<template>
  <ElvMessageBox
    ref="limitReachedMessageBoxRef"
    class="elv-project-limit-reached-dialog"
    :confirmButtonText="enableToUpGradeProject ? t('project.upgradeToStandard') : t('button.talkToSales')"
    :cancelButtonText="t('button.cancel')"
    :title="t('project.limitReached')"
    :loading="confirmLoading"
    @onCancelEvent="changeLimitReachedDialogStatus"
    @onConfirmEvent="onClickConfirm"
  >
    <template #content>
      <div class="elv-project-limit-reached-info">
        {{ limitTypeTipInfo }}
      </div>
    </template>
  </ElvMessageBox>
</template>

<script lang="ts" setup>
import { ElMessage } from 'element-plus'
// import ProjectApi from '@/api/ProjectApi'
import { judgeTalkToSalesLink } from '@/lib/utils'
import { ProjectPermissionLevel } from '#/ProjectTypes'
import { useGlobalStore } from '@/stores/modules/global'
import { useEntityStore } from '@/stores/modules/entity'
import { useProjectStore } from '@/stores/modules/project'
import { useUserGlobalStore } from '@/stores/modules/global/user'
import { isEmpty } from 'lodash-es'

const props = defineProps({
  limitType: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['onConfirmUpgradeProjectPlan'])

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()
const userGlobalStore = useUserGlobalStore()

const confirmLoading = ref(false)
const { user } = storeToRefs(userGlobalStore)
const { projectDetail } = reactive(projectStore)
const limitReachedMessageBoxRef = useTemplateRef('limitReachedMessageBoxRef')

const limitTypeTipInfo = computed(() => {
  if (!props.limitType) return t('message.limitReachedTip')
  if (props.limitType === 'account') {
    return t('message.limitReachedAccountTip')
  }
  if (props.limitType === 'categorize') {
    return t('message.limitReachedCategorizeTip')
  }
  return t('message.limitReachedTip')
})

const currentProject = computed(() => {
  if (!isEmpty(projectDetail)) return projectDetail
  if (entityStore.entityDetail?.projectId || route.params.projectId || route.query.projectId) {
    const projectId =
      entityStore.entityDetail?.projectId || (route.params.projectId as string) || (route.query.projectId as string)
    const project = globalStore.projectDetail(projectId)
    if (project) return project
  }
  return null
})

const enableToUpGradeProject = computed(() => {
  return (
    currentProject.value?.plan === ProjectPermissionLevel.BASIC ||
    currentProject.value?.plan === ProjectPermissionLevel.FREE
  )
})

const changeLimitReachedDialogStatus = () => {
  limitReachedMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 更新项目信息
 */
// const refreshProjectInfo = async () => {
//   try {
//     await globalStore.fetchProjectList()
//     await projectStore.fetchProjectDetail(currentProject.value?.projectId || '')
//   } catch (error: any) {
//     ElMessage.error(error.message)
//   }
// }

const onClickConfirm = async () => {
  if (!enableToUpGradeProject.value) {
    judgeTalkToSalesLink(user.value?.name || '', user.value?.email)
    changeLimitReachedDialogStatus()
    return
  }
  try {
    confirmLoading.value = true
    // await ProjectApi.upgradeProject(currentProject.value?.projectId || '')
    // refreshProjectInfo()
    changeLimitReachedDialogStatus()
    emit('onConfirmUpgradeProjectPlan')
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    confirmLoading.value = false
  }
}

defineExpose({
  changeLimitReachedDialogStatus
})
</script>
<style lang="scss">
.elv-project-limit-reached-dialog {
  width: 470px;

  .el-dialog__body {
    padding: 23px 50px;

    .elv-project-limit-reached-info {
      width: 100%;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-weight: 400;
      color: #1e2024;
      line-height: 20px;
      text-align: center;
      white-space: pre-wrap;
    }
  }

  .elv-confirm-info-footer__confirm {
    width: auto !important;
  }
}
</style>
